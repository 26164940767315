import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { routes } from "@/utils/routes"
import clsx from "clsx"

type Props = {
  removeMargin?: boolean
}

const Footer: React.FC<Props> = ({ removeMargin }) => {
  return (
    <footer className={clsx("relative pt-10 sm:pt-80 pb-8", { "mt-8": !removeMargin })}>
      <StaticImage
        src="../../static/images/footer2023.jpg"
        alt=""
        className="absolute inset-0 -z-[1] hidden sm:block"
        objectFit="cover"
        objectPosition="50% 0"
      />
      <StaticImage
        src="../../static/images/footer2023-mobile.jpg"
        alt=""
        className="absolute inset-0 -z-[1] sm:hidden"
        objectFit="cover"
        objectPosition="50% 0"
      />
      <div className="container text-white px-4">
        <div className="flex flex-col md:flex-row">
          <div className="flex items-center w-full">
            <div className="flex flex-col md:flex-row gap-4 lg:gap-10 items-start">
              <div className="w-full max-w-[95px]">
                <img src="/images/footer-logo.png" alt="RBAI Foundation" />
              </div>
              <div className="w-full">
                <h3 className="text-primary leading-tight">
                  THE ROYAL BELFAST
                  <br />
                  ACADEMICAL INSTITUTION
                </h3>
                <p>
                  College Square East, <br />
                  Belfast, BT1 6DL
                </p>
                <p>
                  T:{" "}
                  <a className="link text-white" href="tel:02890 240461">
                    02890 240461
                  </a>
                  <br />
                  E:{" "}
                  <a
                    className="link text-white"
                    href="mailto:p.anderson@rbai.org.uk"
                  >
                    p.anderson@rbai.org.uk
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full uppercase flex items-center justify-self-end">
            <h2 className="text-[42px] leading-none pt-4 lg:pl-12">
              <Link
                className="text-primary hover:opacity-90"
                to={routes.donate()}
              >
                Donate Now
              </Link>
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-20 text-white px-2">
        <div className="container flex gap-2 flex-col md:flex-row justify-between text-sm leading-relaxed">
          <p>© 2024<br />The Royal Belfast Academical Institution is registered with the Charity Commission for Northern Ireland (Charity Number 108024)</p>
          <p className="flex-shrink-0">
            Powered by{" "}
            <a href="https://payhere.co" target="_blank">
              Payhere
            </a>
          </p>
        </div>
      </div>

      <div
        className="modal fade hidden"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Paviour Pledge - Terms and Conditions
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&nbsp;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="inner_body_txt">
                <div>
                  In return for pledging £800 in support of The Royal Belfast
                  Academical Institution (RBAI), alumni, parents, staff and
                  friends of the School are invited to have their name and years
                  in attendance at RBAI inscribed on a paviour, for example:
                </div>
                <ul>
                  <li>Line 1: ‘Peter Nicol’</li>
                  <li>Line 2 ‘RBAI 1969 – 76’</li>
                </ul>
                <div>
                  <p>
                    A pledge of £800 entitles the donor to a single paviour with
                    2 lines of text. A pledge of £1,600 entitles the donor to a
                    double paviour with 4 lines of text. The maximum number of
                    characters on each line, including spaces, is restricted to
                    16.
                  </p>
                </div>
                <div>
                  <p>
                    Inscriptions must be the names of individuals only - not
                    companies, organisations, clubs or societies.
                  </p>
                </div>
                <div>
                  <p>
                    RBAI reserves the right to approve all lettering and to
                    refuse any application considered unsuitable. In such cases
                    RBAI has no obligation to cite a reason and a full refund
                    will be offered.
                  </p>
                </div>
                <div>
                  <p>
                    Engraving of the paviours will be done by a specialist
                    engraver but names will not be painted - so as to retain the
                    historical look and feel of the School environment.
                  </p>
                </div>
                <div>
                  <p>
                    Donors do not receive a paviour. It will be installed in the
                    School quad and all paviours remain the property of RBAI.
                  </p>
                </div>
                <div>
                  <p>
                    RBAI will have complete control over the location and
                    installation date of the paviours.
                  </p>
                </div>
                <div>
                  <p>
                    The first paviours are scheduled to be installed in the
                    summer of 2021.
                  </p>
                </div>
                <div>
                  <p>
                    RBAI guarantees to maintain the walkway in perpetuity,
                    however the paviours may become worn or damaged over time
                    owing to natural causes. No liability can be accepted for
                    this by RBAI.
                  </p>
                </div>
                <div>
                  <p>
                    Every effort will be made to keep the paviours in the chosen
                    location, however RBAI reserves the right to move or
                    relocate the paviours without liability.
                  </p>
                </div>
                <div>
                  <p>
                    Any amendments to the name provided on the order form must
                    be made within 14 days of receipt of the confirmation
                    letter; thereafter RBAI accepts no responsibility for
                    changed or incorrect names.
                  </p>
                </div>
                <div>
                  <p>Paviours may differ in colour from those illustrated.</p>
                </div>
                <div>
                  <p>
                    For any queries, please contact: Peter Anderson, RBAI
                    Foundation Director. T:{" "}
                    <a href="tel:028 9024 0461">028 9024 0461</a>, E:{" "}
                    <a href="mailto:p.anderson@rbai.org.uk">
                      {" "}
                      p.anderson@rbai.org.uk
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
