import React from "react"
import Helmet from "react-helmet"

type MetaTag =
  | { name: string; content: string }
  | { property: string; content: string }

type Props = {
  title: string
  description?: string
  lang?: string
  meta?: MetaTag[]
}

const SEO: React.FC<Props> = ({ title, description, lang, meta }) => {
  const metaDescription =
    description ||
    "Built on strong and historic principles, RBAI is constantly evolving, and the School’s leadership have a clear vision for the future."

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | RBAI Foundation`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta || [])}
    >
      <link rel="icon" type="image/png" href="/fav.png" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
