import { navigate as reachNavigate } from "@reach/router"
import { makeRouteMap, makeNavigate } from "make-route-map"

const goToRoute = (path: string) => {
  reachNavigate(path)
}

export const routes = makeRouteMap({
  home: {
    path: "/",
  },
  schoolProjects: {
    path: "/school-projects/",
  },
  transforming: {
    path: "/transforming-the-campus/",
  },
  innovation: {
    path: "/centre-of-innovation/",
  },
  scienceDepartmentRenovation: {
    path: "/science-department-renovation/",
  },
  sportingExcellence: {
    path: "/sporting-excellence/",
  },
  robertHoward: {
    path: "/robert-howard/",
  },
  socialMobility: {
    path: "/social-mobility/",
  },
  rugby150: {
    path: "/rugby-150/",
  },
  rugby150payment: {
    path: "/rugby-150-payment/",
  },
  donate: {
    path: "/donate-now/",
  },
  pledge: {
    path: "/pledge-campaign/",
  },
  faqs: {
    path: "/frequently-asked-questions/",
  },
  stayConnected: {
    path: "/contact-preferences/",
  },
  recognisingYourGift: {
    path: "/recognising-your-gift/",
  },
  taxEfficientGiving: {
    path: "/tax-efficient-giving/",
  },
  legacies: {
    path: "/legacies/",
  },
  contactUs: {
    path: "/contact-us/",
  },
  privacy: {
    path: "/privacy-notice/",
  },
  myInchmarlo: {
    path: "/my-inchmarlo/"
  },
  rbaiTogether: {
    path: "/rbai-together/"
  },
  southWingDevelopment: {
    path: "/south-wing-development/"
  },
  southWingFAQs: {
    path: "/south-wing-faqs/"
  },
  shareYourViews: {
    path: "/share-your-views/"
  },
  video: {
    path: "/videos/:slug/",
    params: {
      slug: true
    }
  },
})

export const navigate = makeNavigate(routes, goToRoute)
