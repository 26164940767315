import React, { useState } from "react"
import { Link } from "gatsby"
import HamburgerMenu from "react-hamburger-menu"

import { routes } from "@/utils/routes"
import clsx from "clsx"

type Props = {}

const Header: React.FC<Props> = ({}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className="absolute top-0 right-0 left-0 z-[1] font-brandon">
      <div className="container flex justify-between pt-[20px] lg:pt-[50px] pl-4 relative">
        <div className="w-[50px] md:w-[110px]">
          <Link to={routes.home()} title="RBAI Foundation" className="relative">
            <img src="/logo-hed.png" alt="RBAI Foundation" />
          </Link>
        </div>
        <div>
          <nav
            className={clsx(
              "hidden lg:block absolute top-20 left-4 right-4 text-center py-4 lg:py-0 bg-[#000000e6] lg:relative lg:bg-transparent lg:inset-auto lg:text-start",
              { "!block": menuOpen }
            )}
          >
            <ul
              className={clsx(
                "flex flex-col lg:flex-row gap-4 lg:gap-16 text-white font-medium uppercase text-sm pt-2 leading-tight"
              )}
            >
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="hover:opacity-90"
              >
                <Link title="Transformational Bursaries" to={routes.socialMobility()}>
                  Transformational
                  <br />
                  <span className="text-primary">Bursaries</span>
                </Link>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="hover:opacity-90"
              >
                <Link
                  title="School Projects"
                  to={routes.southWingDevelopment()}
                >
                  The South Wing
                  <br />
                  <span className="text-primary">Development</span>
                </Link>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="hover:opacity-90"
              >
                <Link
                  title="Paviours"
                  to={routes.pledge()}
                >
                  Sponsor a
                  <br />
                  <span className="text-primary">Paviour</span>
                </Link>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="hover:opacity-90 mt-[10px]"
              >
                <Link
                  title="Donate"
                  to={routes.donate()}
                  className="text-primary py-[5px] px-2 border-solid border-primary border-[1px] hover:text-white hover:bg-primary hover:brightness-90"
                >
                  Donate
                </Link>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
                className="hover:opacity-90 relative group lg:-translate-x-8 lg:-mr-6"
              >
                <a
                  title="Dropdown"
                  href="#"
                  className="hidden lg:flex items-center justify-center w-[40px] h-[40px] hover:bg-primary hover:rotate-90 rounded-full transition-all"
                >
                  <img
                    src="/images/icon-header.png"
                    alt="Dropdown"
                    className="w-[5px]"
                  />
                </a>
                <ul
                  role="menu"
                  className="lg:absolute top-14 right-0 text-white lg:text-dark shadow-lg flex-col z-[1] flex lg:hidden group-hover:flex"
                >
                  <span className="hidden lg:block absolute top-0 right-0 left-0 h-4 -translate-y-[100%]"></span>
                  <span className="hidden lg:block absolute top-0 right-2 -translate-y-[100%] border-r-[12px] border-l-[12px] border-r-solid border-l-solid border-r-transparent border-l-transparent border-b-white border-b-solid border-b-[12px]"></span>

                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="FAQ's"
                      to={routes.faqs()}
                    >
                      FAQs
                    </Link>
                  </li>
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="Stay Connected"
                      to={routes.stayConnected()}
                    >
                      Stay Connected
                    </Link>
                  </li>
                  {/* <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="Share Your Views"
                      to={routes.shareYourViews()}
                    >
                      Share Your Views
                    </Link>
                  </li> */}
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="Recognising your Gift"
                      to={routes.recognisingYourGift()}
                    >
                      Recognising your Gift
                    </Link>
                  </li>
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="Tax Efficient Giving"
                      to={routes.taxEfficientGiving()}
                    >
                      Tax Efficient Giving
                    </Link>
                  </li>
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="Legacies"
                      to={routes.legacies()}
                    >
                      Legacies
                    </Link>
                  </li>
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="RBAI Together"
                      to={routes.rbaiTogether()}
                    >
                      RBAI Together
                    </Link>
                  </li>
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="FAQ's"
                      to={routes.rugby150()}
                    >
                      Rugby 150 Book
                    </Link>
                  </li>
                  <li
                    itemScope
                    itemType="https://www.schema.org/SiteNavigationElement"
                    className="hover:opacity-90 lg:bg-white"
                  >
                    <Link
                      className="block hover:text-primary transition-colors py-2 pr-4 pl-2 lg:hover:bg-black/10"
                      title="Contact Us"
                      to={routes.contactUs()}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div className="block lg:!hidden mt-4 mr-4 cursor-pointer">
            <HamburgerMenu
              isOpen={menuOpen}
              menuClicked={() => setMenuOpen((open) => !open)}
              width={30}
              height={20}
              strokeWidth={4}
              color="white"
              borderRadius={5}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
