import "../styles/global.css"
import "react-circular-progressbar/dist/styles.css"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "payhere-embed-sdk/dist/react.css"
import React, { ReactElement } from "react"

import Header from "@/components/Header"
import Footer from "@/components/Footer"
import clsx from "clsx"

interface Props extends React.PropsWithChildren {
  removeFooterMargin?: boolean
  className?: string
  sectionOne?: ReactElement
  wrapperClassName?: string
}

const Layout: React.FC<Props> = ({
  removeFooterMargin,
  className,
  children,
  sectionOne = <></>,
  wrapperClassName,
}) => {
  return (
    <div className={className}>
      <div className={clsx("relative", wrapperClassName)}>
        <Header />
        {sectionOne}
      </div>
      {children}

      <Footer removeMargin={removeFooterMargin} />

      {/* <Popup /> */}
    </div>
  )
}

export default Layout
